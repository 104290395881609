
import React, { useEffect, useState } from 'react'
import NavBar from './NavBar';
import HomeBody from './Body';
import axios from 'axios';


function HomePage() {


   

   
  return (
    <>
    <NavBar/>
    <HomeBody/>

    </>
    
  )
}

export default HomePage;